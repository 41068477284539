.App {
  text-align: left;
  height: 100%;
}

.App .title {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 24px;
  color: white;
  font-size: 36px;
}

.pageTitle {
  color: white;
  padding-left: 24px;
}

.pageBody {
  color: white;
  padding-left: 24px;
  padding-right: 24px;
}

#root {
  height: 100%;
  margin: 0;
}

html, body {
  background-color: black;
  height: 100%;
  margin: 0;
}

.likes {
  display: flex;
  align-items: center;
  width: 64px;
  flex-grow : 1;
}

.likes p {
  margin-left: 8px;
}

.userProfile {
  display: flex; 
  flex-flow: column;
  align-items: center;
}

.userProfile h1 {
  color: white;
  font-size: 36px;
}

.profilePhoto {
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 1px solid white;
  width: 35%;
  margin-top: 20%;
}

.outlineButton {
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  width: 250px;
}

.outlineButton * {
  color: white;
  padding: 10px 10px;
  border: 1px solid white;
  font-size: large;
}

.outlineButton:hover * {
  color: black;
  background-color: white;
  cursor: pointer;
}

footer {
  background-color: black;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
}

nav{
  display: flex;
  align-items: center;
  min-height: 3vh;
  max-height: 8vh;
  border: 1px solid white;
  color: white;
}

.avatarContainer {
  height: 100%
}
nav a {
  text-decoration: none;
  padding-left: 24px;
  padding-right: 24px;
  height: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
}

nav a.active {
  background-color: white;
  
}

nav h2 {
  color: white;
}

nav a.active h2 {
  color: black;
}

section {
  vertical-align: top;
}

main {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  padding: 1px 10px;
  color: white;
  flex-grow: 1;
}

.post {
   border: 1px solid white;
   
}

.description { 
  padding-left: 8px;
  display: flex;
  flex-flow: column;
}

.post h3 { 
  margin-top: 8px;
  margin-bottom: 8px;
  padding-right: 8px;
  max-width: 90%;
  display: block; /* or inline-block */
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 4.5em;
  line-height: 1.5em;
}

.container {
  width: 100%;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  position: relative; 
}

.container .angular {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.container .haptic {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.intensity {
  position: relative;
  top: 0px;
  height: 50%;
}

.separator {
  height: 1px;
  background-color: #222222;
}

.sharpness {
  position: relative;
  bottom: 0px;
  height: 50%;
}

.transient {
  position: absolute;
  bottom: 0px;
  border-radius: 5px;
}

.continuous {
  position: absolute;
  bottom: 0px;
  border-radius: 5px;
}

.container svg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.avatar {
  height: 36px;
  aspect-ratio: 1;
  border-radius: 36px;
  border: 1px solid white;
}

.splash {
  display: flex; 
  flex-flow: column;
  align-items: center;
  justify-content: top;
  height: 100%;
}

.appIcon {
  aspect-ratio: 1;
  height: 40%;
}

.googleSignIn  {
  border: 1px solid white;
  padding: 0px;
  display: flex;
  background-color: rgba(0, 0, 0, 0);
}

.googleSignIn img {
  padding: 0px;
  margin: 0px;
}

.googleSignIn h2 {
  color: white;
  padding-right: 8px;
  padding-left: 4px;
}

.homeLink {
  height: 64px;
  width: 64px;
}